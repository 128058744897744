import {
  useCoAgent,
  useCopilotChat,
  useCopilotMessagesContext,
} from '@copilotkit/react-core';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useUserData } from '../hooks/useUserData';
import { Reference } from '../../pages/ai-adviser/components/ChatComponents/References';
import {
  Role,
  Message,
  ActionExecutionMessage,
  ResultMessage,
  TextMessage,
  AgentStateMessage,
} from '@copilotkit/runtime-client-gql';
import { MessageFeedback } from '../../pages/ai-adviser/components/ChatComponents/ResponseFeedback';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGES } from '../constants';

type AgentState = {
  user_id: string;
  project_id: string;
  references?: Reference[];
  follow_up_questions?: string[];
  message_feedback?: MessageFeedback;
};

interface ChatbotContextValue {
  activeProject: string;
  setActiveProject: Dispatch<SetStateAction<string | null>>;
  chatbotState: AgentState;
  feedback: MessageFeedback;
  setFeedback: Dispatch<SetStateAction<MessageFeedback | null>>;
  getLastAgentMessage: () => Message;
  visibleMessages: Message[];
}

const ChatbotContext = createContext<ChatbotContextValue>(null);

export const ChatbotProvider = ({ children }) => {
  const [activeProject, setActiveProject] = useState<string>(null);
  const [feedback, setFeedback] = useState<MessageFeedback>(null);
  const { user } = useUserData();
  const { messages, setMessages } = useCopilotMessagesContext();
  const { visibleMessages } = useCopilotChat();
  const { enqueueSnackbar } = useSnackbar();
  const { state, setState } = useCoAgent<AgentState>({
    name: 'chat_agent',
  });

  const getLastAgentMessage = () => {
    return messages
      .slice()
      .reverse()
      .find(
        (message) => message.isTextMessage() && message.role === Role.Assistant
      );
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      user_id: user?.id,
      project_id: activeProject,
      message_feedback: feedback,
    }));
  }, [user?.id, activeProject, feedback?.message_id]);

  useEffect(() => {
    if (messages.length !== 0) {
      try {
        localStorage.setItem('copilotkit-messages', JSON.stringify(messages));
        // store feedback if exists
        if (feedback) {
          localStorage.setItem('messageFeedback', JSON.stringify(feedback));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(ERROR_MESSAGES.SAVE_DATA_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    }
  }, [JSON.stringify(messages), JSON.stringify(feedback)]);

  useEffect(() => {
    const messages = localStorage.getItem('copilotkit-messages');
    if (messages) {
      const parsedMessages = JSON.parse(messages).map((message: any) => {
        if (message.type === 'TextMessage') {
          return new TextMessage({
            id: message.id,
            role: message.role,
            content: message.content,
            createdAt: message.createdAt,
          });
        } else if (message.type === 'ActionExecutionMessage') {
          return new ActionExecutionMessage({
            id: message.id,
            name: message.name,
            scope: message.scope,
            arguments: message.arguments,
            createdAt: message.createdAt,
          });
        } else if (message.type === 'ResultMessage') {
          return new ResultMessage({
            id: message.id,
            actionExecutionId: message.actionExecutionId,
            actionName: message.actionName,
            result: message.result,
            createdAt: message.createdAt,
          });
        } else if (message.type === 'AgentStateMessage') {
          return new AgentStateMessage({
            id: message.id,
            createdAt: message.createdAt,
            status: message.status,
            agentName: message.agentName,
            state: message.state,
            running: message.running,
            threadId: message.threadId,
            role: message.role,
            nodeName: message.nodeName,
            runId: message.runId,
            active: message.active,
          });
        } else {
          throw new Error(`Unknown message type: ${message.type}`);
        }
      });
      setMessages(parsedMessages);
    }

    // load feedback with messages
    const feedback = localStorage.getItem('messageFeedback');
    if (feedback) {
      setFeedback(JSON.parse(feedback));
    }
  }, []);

  useEffect(() => {
    const savedProject = localStorage.getItem('activeProject');
    if (savedProject) {
      setActiveProject(savedProject);
    }
  }, []);

  useEffect(() => {
    if (activeProject) {
      localStorage.setItem('activeProject', activeProject);
    }
  }, [activeProject]);

  const value: ChatbotContextValue = {
    activeProject,
    setActiveProject,
    chatbotState: state,
    feedback,
    setFeedback,
    getLastAgentMessage,
    visibleMessages,
  };

  return (
    <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>
  );
};

export const useChatbotContext = () => useContext(ChatbotContext);
