export const spacings = {
  px8: '8px',
  px12: '12px',
  px16: '16px',
  px20: '20px',
  px24: '24px',
  px32: '32px',
  px48: '48px',
  px64: '64px',
};
