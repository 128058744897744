import React, { FC } from 'react';
import { NavigationRegisterPrompt } from './NavigationRegisterPrompt';
import {
  Box,
  Button,
  Hidden,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { Icon } from '../../../components/Icon/Icon';
import Link from 'next/link';
import { LogoutIcon } from '../../../components/Icon/Icons/Logout.icon';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';
import { ButtonProps } from '@mui/material/Button/Button';
import { logOut } from '../../../utils/authHelpers';
import { Routes } from '../../../enums/routes';
import { getNavigationStructure } from './getNavigationStructure';
import { useUserData } from '../../../hooks/useUserData';
import { useRouter } from 'next/router';
import { useWhiteLabelContext } from '../../WhitelabeProvider';
import { ProfilePicture } from '../../../components/profile/ProfilePicture';
import { AiAdviserIcon } from '../../../components/Icon/Icons/AiAdviser.icon';
import { colors } from '../../../styles/colors';
import { spacings } from '../../../styles/spacings';

export const Navigation: FC = () => {
  const router = useRouter();
  const { isAdmin, user, loading } = useUserData();

  const handleLogout = async () => {
    await logOut(() => router.push(Routes.Login));
  };

  const showRegisterPrompt =
    !user &&
    !loading &&
    ![
      Routes.Login,
      Routes.Registration,
      Routes.AuthRequestPasswordReset,
    ].includes(router.pathname as Routes);

  const { communityLink, linkToFeedbackForm, isLinkToPartnersVisible } =
    useWhiteLabelContext();

  // TODO: move useWhiteLabelContext() hook to getNavigationStructure
  const navigationStructure = getNavigationStructure({
    user,
    isAdmin,
    communityLink,
    linkToFeedbackForm,
    isLinkToPartnersVisible,
  });

  return (
    <>
      {showRegisterPrompt && <NavigationRegisterPrompt />}

      {user?.profile && user?.onboardingData && (
        <>
          {navigationStructure.map((section, index) => (
            <Box
              marginBottom={index === navigationStructure.length - 1 ? 0 : 7}
              key={section.header}
            >
              <Box marginY={1}>
                <Typography variant="overline">{section.header}</Typography>
              </Box>

              {section.links.map(
                ({ href, label, linkIcon, hide, external, className }) => {
                  if (hide) {
                    return null;
                  }

                  if (external) {
                    return (
                      <MuiLink
                        href={href}
                        key={label}
                        underline="none"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <StyledButton
                          fullWidth
                          href={href}
                          rel="noreferrer noopener"
                        >
                          <Icon Component={linkIcon} />
                          <Typography>{label}</Typography>
                        </StyledButton>
                      </MuiLink>
                    );
                  }

                  return (
                    <>
                      <Link href={href} key={label}>
                        <StyledButton
                          className={className}
                          fullWidth
                          href={href}
                          $isSelected={href === router.pathname}
                        >
                          {href === Routes.UserProfileEdit ? (
                            <ProfilePicture
                              size={24}
                              profilePictureUrl={user?.profilePicture}
                            />
                          ) : (
                            <Icon Component={linkIcon} />
                          )}
                          <Typography>{label}</Typography>
                        </StyledButton>
                      </Link>
                    </>
                  );
                }
              )}
            </Box>
          ))}

          <StyledButton fullWidth onClick={handleLogout}>
            <Icon Component={LogoutIcon} />
            <Typography align="left">
              <Trans>Odhlásit se</Trans>
            </Typography>
          </StyledButton>
        </>
      )}
    </>
  );
};

const StyledButton = styled(Button)<ButtonProps & { $isSelected?: boolean }>`
  ${(props) =>
    props.$isSelected &&
    css`
      background-color: rgba(255, 255, 255, 0.05);
    `}

  display: grid;
  color: white;
  grid-template-columns: auto 1fr;
  grid-gap: ${spacings.px16};
  cursor: pointer;
  align-items: center;
`;

const AiButton = styled(Button)<{ $isSelected?: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 200px;
  background-color: white;
  color: ${colors.black};
  border: 2px solid
    ${(props) => (props.$isSelected ? colors.secondary : colors.white)};
  margin: ${spacings.px64} 0;

  &:hover {
    background-color: ${colors.white};
    border: 2px solid ${colors.secondary};
  }

  &:disabled {
    border: 2px solid ${colors.grey};
    // TODO: fix svg color change
    svg {
      rect {
        fill: ${colors.grey};
        }

        path {
          &:last-of-type {
            fill: ${colors.grey}
          }
        }
      }
    }
  }
`;
