export const colors = {
  brand: '#093C8F',
  brandLight: '#2053a7',
  secondary: 'rgba(255,162,0,0.9)',
  secondaryLight: '#ffa200c9',
  lightBlue: '#e6ecf4',
  greyBlue: '#d5e0ee',
  cardLightBlue: '#E7F0FC',
  blueInput: '#F1F5F7',
  blueInputBorder: '#AFCBF2',
  green: '#4EB57B',
  greenLight: '#65cd91',
  greenBanner: '#dbf0e4',
  white: '#FFFFFF',
  lightGrey: '#F8F8F8',
  grey: '#CCD0D7',
  darkerGrey: '#7F8A9C',
  darkGrey: '#666666',
  black: '#141125',
  error: '#E73024',
  errorLight: '#E73024',
  buttonLightBlue: '#DBE1EB',
};
