import { Step } from 'react-joyride';
import { t } from '@lingui/macro';
import { noHightlightStyles } from '../../styles/tooltipStyles';

type StepsGetter = () => Step[];

export const getDiaryTutorialSteps: StepsGetter = () => [
  {
    title: t`Deník projektu`,
    content: t`Jak to vlastně funguje a co zde najdete? `,
    target: 'body',
    showSkipButton: true,
    placement: 'center',
  },
  {
    title: t`Fáze projektu`,
    content: t`Průvodce má čtyři fáze, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.`,
    target: '.diary-tutorial-step-1',
    placement: 'bottom',
  },
  {
    title: t`Doporučeno přímo tobě`,
    content: t`Podle toho, v jaké jsi fázi, ti zvýrazňujeme některé kapitoly. I když už jsi dál, doporučujeme nepřeskakovat předchozí fáze. Mohl/a jsi něco velmi důležitého přeskočit.`,
    target: '.diary-tutorial-step-2',
    placement: 'bottom',
  },
  {
    title: t`Sleduj tvůj pokrok`,
    content: t`Jak budeš Deníkem procházet, budeme ti ukazovat, kde se nacházíš a kolik ti ještě zbývá.`,
    target: '.diary-tutorial-step-3',
    placement: 'right',
  },
  {
    title: t`Vyzkoušej AI Poradce!`,
    content: t`Chatovací nástroj, který ti pomůže ověřit tvé inovativní nápady a odpoví na otázky ohledně podnikání a startupů.`,
    target: '.ai-button',
    placement: 'right',
    spotlightPadding: 0,
  },
];

export const getHowToPageSteps: StepsGetter = () => [
  {
    title: t`Kapitola`,
    content: t`Každá kapitola se skládá ze tří částí: video s podnikateli, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění udělat.`,
    target: '.howTo-tutorial-step-1',
    placement: 'center',
    showSkipButton: true,
    disableScrolling: true,
  },

  {
    title: t`Poznámky`,
    content: t`Pokud chceš, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu se může hodit pro potenciální investory i spoluzakladatele.`,
    target: '.howTo-tutorial-step-2',
    placement: 'bottom',
  },

  {
    title: t`Pokračování`,
    content: t`Jakmile budeš s kapitolou hotov, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.`,
    target: '.howTo-tutorial-step-3',
  },
];

export const getChatbotSteps: StepsGetter = () => [
  {
    title: t`Jak AI Poradce funguje?`,
    content: t`AI Poradce je chatbot poháněný umělou inteligencí. Naučili jsme ho využívat náš vlastní StartupBox obsah. Díky tomu ti interaktivní formou pomůže nastudovat informace z oblastí jako jsou ověření nápadu, inovativní myšlení, podnikání a startupy. Stačí si s ním jen psát.`,
    target: '.hints',
    showSkipButton: true,
    disableBeacon: true,
    ...noHightlightStyles,
  },
  {
    title: t`Doporučená témata`,
    target: '.hints',
    content: t`AI Poradce ti na úvod konverzace nabídne otázky, které by tě mohly zajímat nejvíce. Jsou to témata vybraná na míru právě tobě. Jakmile na jedno z nich klikneš spustí se konverzace. Podobné otázky ti AI Poradce bude nabízet i v průběhu konverzace.`,
    placement: 'bottom',
  },
  {
    title: t`Jednoduchá komunikace`,
    content: t`Konverzaci můžeš samozřejmě začít i svojí vlastní otázkou. Jakýkoliv text piš přímo do řádku a k odeslání použij šipku vpravo, nebo klávesu Enter. Konverzace se bude přirozeně odvíjet v jednom okně před tebou.`,
    target: '.copilotKitInput',
    placement: 'bottom',
  },
];

export const getDashboardSteps: StepsGetter = () => [
  {
    title: t`Vyzkoušej AI Poradce!`,
    content: t`Chatovací nástroj, který ti pomůže ověřit tvé inovativní nápady a odpoví na otázky ohledně podnikání a startupů.`,
    target: '.ai-button',
    placement: 'right',
    disableBeacon: true,
    spotlightPadding: 0,
    spotlightClicks: true,
  },
];
